import './App.css';
import { useEffect } from 'react';
import { Route, Routes } from "react-router-dom";
import Home from './Pages/Home/Home';
import VisionImage from './Pages/VisionImage/VisionImage';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Cookies from "universal-cookie";
const cookies = new Cookies();



function App() {

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/home" element={<Home />} />
      <Route path="/processed-image" element={<VisionImage />} />
    </Routes>
  );
}

export default App;
