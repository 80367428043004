import React, { useState, useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import html2canvas from 'html2canvas';
import { googleLens } from '../../Components/APIS/api'
import style from './VisionImage.module.css'
import { Link } from 'react-router-dom';
import { ThreeCircles } from 'react-loader-spinner'
import { useNavigate } from 'react-router-dom';


function VisionImage() {

    const [visualMatches, setVisualMatches] = useState([])
    const [labels, setLabels] = useState([])
    const [merchandiseImages, setMerchandiseImages] = useState([])
    const [loader, setLoader] = useState(false)
    const location = useLocation()
    const { result } = location.state
    const { image } = location.state
    const { type } = location.state
    const navigate = useNavigate()


    const screenshotCanvasRef = useRef(null);


    const handleScreenshotImageClick = (e, i) => {
        setLoader(true)
        // const boundingBox = document.getElementById(`boundingBox${i}`);
        // console.log(boundingBox, i)
        // const rect = boundingBox?.getBoundingClientRect();
        // const left = rect.left;
        // const top = rect.top;
        // const width = rect.width;
        // const height = rect.height;
        // captureScreenshot(left, top, width, height);
    };

    const captureScreenshot = (left, top, width, height) => {
        // const targetElement = document.getElementById('pinpointContainer');

        // html2canvas(targetElement, {
        //     x: left,
        //     y: top - 100,
        //     width: width,
        //     height: height,
        // }).then(async (canvas) => {
        //     const screenshotImage = new Image();
        //     console.log(screenshotImage)

        //     screenshotImage.src = canvas.toDataURL();
            
        //     const data = await googleLens(screenshotImage.src);
        //     if (data.status === true) {
        //         setVisualMatches(data.data.visual_matches);
        //         setLoader(false)
        //     }
        //     // Call your image processing function (e.g., googleLens) here

        // });
    };

    const homePage = () => {
        navigate('/home')
    };

  
    // useEffect(() => {
    //     setLoader(true)
  
    // }, [])


    useEffect(() => {
        setLoader(true)
        if(type == 'object'){
            const objects = result[0].localizedObjectAnnotations;
        // Filter out objects other than "Person"
        // const filteredObjects = objects.filter(obj => obj.name !== "Person");
       
      
        // setTimeout(() => {
            const data = []
            objects.forEach((coord, i) => {
                data.push({description:coord.name})
            });
            setLabels(data)
        }else{
            const objects = result[0].labelAnnotations;
            setLabels(objects);
        }   
        setLoader(false)
        // }, 2000);
          
    }, [])


    return (
        <>
            <button onClick={(e) => {homePage()}} className='btn btn-warning' style={{margin: '30px auto  0', width: '200', display: 'table' }}> Back</button>
            <canvas ref={screenshotCanvasRef} style={{ display: 'none' }}></canvas>
            <div id="pinpointContainer" className='position-relative mt-4' style={{margin: '0 auto', width: '300px', display: 'table' }}>
                <img src={image} width='100%' id='pinpointImage' alt="" />
            </div>
            <div style={{margin: '0 auto', width: '80%', display: 'table' }}>
                <div className='row mx-3 mt-4'>
                    {labels && labels.map((x, i) => (
                        <div className='col-3' key={i}>
                            <div className={`${style.card} card mb-1 text-center`}>
                                <div className="card-body text-center mt-1 p-0">
                                    <p className={`${style.description} card-title fw-bold`} style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', padding: "0 8px", }}>{x.description}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                    {labels.length === 0 && labels.length === 0 ? <p className={`${style.no_data}`} style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', padding: "0 8px", }}>No Data Detected</p> : ''}
                </div>
            </div>
            <ThreeCircles
                height="100"
                width="100"
                color="#760166"
                wrapperStyle={{ margin: 'auto' }}
                wrapperClass={`${style.loader}`}
                visible={loader}
                ariaLabel="three-circles-rotating"
                outerCircleColor=""
                innerCircleColor=""
                middleCircleColor=""
            />
        </>

    )
}

export default VisionImage