import axios from "axios";

const BASE_URL = `https://eurekalive.tdemo.biz/api/`
const headers = {
    accept: "application/json",
};

export const HomeApi = async () => {
    let { data } = await axios.get(`${BASE_URL}home-screen`, { headers })
    return data
}

export const location = async () => {
    let { data } = await axios.get(`${BASE_URL}location/list`, { headers })
    return data
}

export const individualSliderList = async (item_ID, name) => {
    if (name === 'home-slider') {
        let { data } = await axios.get(`${BASE_URL}get-show-related-data/${item_ID}`, { headers })
        return data
    } else if (name === 'people') {
        let { data } = await axios.get(`${BASE_URL}get-outfits-by-character?name=${item_ID}`, { headers })
        return data
    }
}

export const userRegisteration = async (userCredentials) => {
    let request = {};
    request["email"] = `${userCredentials && userCredentials.email}`;
    request["password"] = `${userCredentials && userCredentials.password}`;
    request["name"] = `${userCredentials && userCredentials.full_name}`;
    request["phone"] = `${userCredentials && userCredentials.phone}`;
    request["platform"] = `app`;
    request["social_id"] = ``;
    let { data } = await axios.post(`${BASE_URL}register`, request, { headers })
    return data
}

export const userLogin = async (userCredentials) => {
    let request = {};
    request['email'] = `${userCredentials && userCredentials.email}`;
    request['password'] = `${userCredentials && userCredentials.password}`;
    request['platform'] = `app`;
    let { data } = await axios.post(`${BASE_URL}login`, request, { headers })
    return data
}

export const verifyUser = async (userEmail, otp, isRegister) => {
    let request = {};
    request['email'] = `${userEmail}`;
    request['code'] = `${otp}`;
    request['is_from_reg'] = `${isRegister}`;
    let { data } = await axios.post(`${BASE_URL}password/verify`, request, { headers })
    return data
}

export const resendCode = async (userEmail) => {
    let request = {};
    request['email'] = `${userEmail}`;
    let { data } = await axios.post(`${BASE_URL}resend/code`, request, { headers })
    return data
}

export const forgotPassword = async (userEmail) => {
    let request = {};
    request['email'] = `${userEmail}`
    let { data } = await axios.post(`${BASE_URL}password/forgot`, request, { headers })
    return data
}

export const resetPassword = async (email, code, password) => {
    let request = {};
    request['email'] = `${email}`
    request['code'] = `${code}`
    request['password'] = `${password}`
    let { data } = await axios.post(`${BASE_URL}password/reset`, request, { headers })
    return data
}

export const productDetail = async (id, authentication, token) => {

    const authHeader = {
        accept: "application/json",
        'Authorization': `Bearer ${token}`
    }

    if (authentication) {
        let { data } = await axios.get(`${BASE_URL}get-outfit-related-data-with-auth/${id}`, { headers: { ...authHeader } })
        return data
    } else {
        let { data } = await axios.get(`${BASE_URL}get-outfit-related-data/${id}`, { headers })
        return data
    }
}

export const productRating = async (rating, id, token) => {

    const authHeader = {
        accept: "application/json",
        'Authorization': `Bearer ${token}`
    }

    let request = {};
    request['rating'] = `${rating}`
    request['outfit_id'] = `${id}`
    let { data } = await axios.post(`${BASE_URL}add/outfit/rating`, request, { headers: { ...authHeader } })
    return data
}

export const productFavourite = async (id, token) => {

    const authHeader = {
        accept: "application/json",
        'Authorization': `Bearer ${token}`
    }

    let { data } = await axios.get(`${BASE_URL}favourite/sync/${id}`, { headers: { ...authHeader } })
    return data
}

export const updateUserProfile = async (userCredentials, token) => {
    const authHeader = {
        accept: "application/json",
        'Authorization': `Bearer ${token}`
    }
    let request = {};
    request["email"] = `${userCredentials && userCredentials.email}`;
    request["name"] = `${userCredentials && userCredentials.full_name}`;
    request["phone"] = `${userCredentials && userCredentials.phone}`;

    let { data } = await axios.post(`${BASE_URL}update/profile`, request, { headers: { ...authHeader } })
    return data
}

export const getFavourite = async (token) => {

    const authHeader = {
        accept: "application/json",
        'Authorization': `Bearer ${token}`
    }

    let { data } = await axios.get(`${BASE_URL}get-for-you-list`, { headers: { ...authHeader } })
    return data
}

export const search = async (query) => {
    let { data: { data } } = await axios.get(`${BASE_URL}search?query=${query}`, { headers })
    return data;
}

export const analyzePhoto = async (imgData, type) => {

    let { data: { responses } } = await axios.post(`https://vision.googleapis.com/v1/images:annotate?key=AIzaSyCJ-nea5ZVTDnrHewa8lKym3tlru7VesDA`, {
        requests: [
            {
                image: {
                    content: imgData.split(',')[1],
                },
                features: [
                    {
                        maxResults: 50,
                        type: type == "object" ? "OBJECT_LOCALIZATION" : 'LABEL_DETECTION'
                    },
                ],
            },
        ],
    }, { headers })
    return responses
}

export const googleLens = async (img) => {

    let request = {};
    request["image"] = `${img && img}`;
    request["type"] = `web`;

    let { data } = await axios.post(`${BASE_URL}get-live-url`, request, { headers })
    return data;
}

export const locationDetails = async (id) => {
    let { data } = await axios.get(`${BASE_URL}location/list/${id}`, { headers })
    return data;
}

export const similarLocation = async (type, lat, long) => {
    let { data } = await axios.get(`${BASE_URL}search/locations?lat=${lat}&lng=${long}&type=${type}`, { headers })
    return data;
}

export const TrendingMerchandiseList = async (id) => {
    let { data } = await axios.get(`${BASE_URL}get-related-outfits/${id}`, { headers })
    return data;
}

export const addMerchandiseItem = async (itemDetails, token) => {

    const authHeader = {
        accept: "application/json",
        'Authorization': `Bearer ${token}`
    }

    let request = {};
    request["name"] = `${itemDetails && itemDetails.item_name}`;
    request["description"] = `${itemDetails && itemDetails.item_description}`;
    request["price"] = `${itemDetails && itemDetails.item_price}`;
    request["url"] = `${itemDetails && itemDetails.item_url}`;
    request["mainImage"] = `${itemDetails && itemDetails.mainimg}`;
    request["itemImage"] = `${itemDetails && itemDetails.itemimg}`;
    request["type"] = `web`;

    let { data } = await axios.post(`${BASE_URL}item/add`, request, { headers: { ...authHeader } })
    return data;
}

export const getMerchandiseItem = async (token) => {

    const authHeader = {
        accept: "application/json",
        'Authorization': `Bearer ${token}`
    }

    let { data } = await axios.get(`${BASE_URL}item/list`, { headers: { ...authHeader } })
    return data
}


export const deleteMerchandiseItem = async (id, token) => {

    const authHeader = {
        accept: "application/json",
        'Authorization': `Bearer ${token}`
    }

    let { data } = await axios.delete(`${BASE_URL}item/${id}`, { headers: { ...authHeader } })
    return data
}